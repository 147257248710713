<template>
    <div class="letter">
        <BackBar :pageTit="headTit" />
        <ul>
            <li v-for="item in rows" :key="item" @click="$router.push('/MsgRoom/'+item.id)">
                <div class="tx" :style="{'background-image':'url('+item.headimgurl+')'}"></div>
                <div class="letter_con">
                    <div class="letter_top">{{item.title}} <!-- <span>上午09:15</span> --></div>
                    <div class="letter_bot">
                        <!--
                        <p>与其抱怨老板关注细节，不如比老板更细节234</p>
                        <div class="newsNum">3</div> -->
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";

import Api from '@/api'
export default {
    name:'Letter',
    data(){
        return{
            headTit:'私信',
            rows:[]
        }
    },
    mounted(){
        var that=this
        Api.go('msg_list',{userid:this.$route.params.toid},(res)=>{
            that.rows=res
        })
    },
    components: {
        BackBar,
    },
}
</script>
<style lang="less" scoped>
.letter{
    background: #fff;
    ul{
        // padding:0 .32rem;
        li{
            padding:.28rem 0 0 .32rem;
            display: flex;
            align-items: center;
            .tx{
                width: .96rem;
                height: .96rem;
                border-radius: 50%;
                background: #ccc;
                margin-right: .2rem;
                background-size: cover;
            }
            .letter_con{
                width: 6.02rem;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                padding-bottom: .28rem;
                padding-right: .32rem;
                box-sizing: border-box;
                .letter_top{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: .3rem;
                    color: #333;
                    margin-bottom: .08rem;
                    span{
                        font-size: .22rem;
                        color: #aaa;
                    }
                }
                .letter_bot{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    P{
                        width: 4.94rem;
                        font-size: .26rem;
                        color: #777;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                    }
                    .newsNum{
                        width: .36rem;
                        height: .36rem;
                        line-height: .36rem;
                        background: #F65102;
                        border-radius: 50%;
                        font-size: .22rem;
                        color: #fff;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>